import React from 'react';
import { shape } from 'prop-types';
import FooterComponent from '@magalu/stereo-ui/templates/Footer';
import withLayoutProps from '../../../hocs/withLayoutProps';
var Footer = function Footer(_ref) {
  var staticProps = _ref["static"];
  return React.createElement(FooterComponent, staticProps);
};
Footer.defaultProps = {
  "static": {}
};
process.env.NODE_ENV !== "production" ? Footer.propTypes = {
  "static": shape({})
} : void 0;
Footer.ssr = true;
Footer.displayName = 'Footer';
export default withLayoutProps(Footer);