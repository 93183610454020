import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect, useState } from 'react';
import Strip from '@magalu/stereo-ui/atoms/Strip';
import { shape } from 'prop-types';
import { getCookie, setCookie, cookiesConstants, routePush } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
import { CloseGateKeeper, DialogStyled, Divisor, DivisorContainer, GatekeeperButton, Header, Logo, ModalContent, Subtitle, Title } from './Gatekeeper.styles';
var Gatekeeper = function Gatekeeper(_ref) {
  var structure = _ref.structure,
    config = _ref.config,
    staticProps = _ref["static"];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var waitTime = staticProps.waitTime,
    waitPointerTime = staticProps.waitPointerTime,
    exhibitionLimit = staticProps.exhibitionLimit,
    textContent = staticProps.textContent,
    appUrl = staticProps.appUrl,
    appUrlDeepLink = staticProps.appUrlDeepLink;
  var title = textContent.title,
    subtitle = textContent.subtitle,
    divisor = textContent.divisor,
    mainButton = textContent.mainButton,
    closeGatekeeper = textContent.closeGatekeeper;
  var publicRuntimeConfig = config.publicRuntimeConfig;
  var cookieDomains = publicRuntimeConfig == null ? void 0 : publicRuntimeConfig.cookieDomain;
  var domain = cookieDomains ? cookieDomains[structure == null ? void 0 : structure.site] || (cookieDomains == null ? void 0 : cookieDomains["default"]) : null;
  var encodeAppURL = encodeURIComponent(appUrlDeepLink);
  var appLink = appUrl.concat(encodeAppURL);
  var GATEKEEPER_EXHIBITION = cookiesConstants.GATEKEEPER_EXHIBITION;
  var gatekeeperExhibitionRule = getCookie(GATEKEEPER_EXHIBITION);
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    open = _useState2[0],
    setOpen = _useState2[1];
  var _useState3 = useState('none'),
    _useState4 = _slicedToArray(_useState3, 2),
    pointerEvents = _useState4[0],
    setPointerEvents = _useState4[1];
  var setGatekeeperCookies = function setGatekeeperCookies(value) {
    setCookie({
      domain: domain,
      name: GATEKEEPER_EXHIBITION,
      value: value
    });
  };
  var handleCloseGatekeeper = function handleCloseGatekeeper() {
    if (pointerEvents === 'all') {
      setOpen(false);
    }
  };
  var handleSendToApp = function handleSendToApp() {
    publish('gatekeeper:click');
    setGatekeeperCookies(exhibitionLimit);
    setOpen(false);
    routePush({
      path: appLink
    });
  };
  useEffect(function () {
    var waitTimerId;
    var pointerTimerId;
    var cookieValue = Number.isNaN(+gatekeeperExhibitionRule) ? 0 : +gatekeeperExhibitionRule;
    if (cookieValue < exhibitionLimit) {
      waitTimerId = setTimeout(function () {
        var isBodyOverflowVisible = document.body.style.overflow !== 'hidden';
        if (isBodyOverflowVisible) {
          setOpen(true);
          setGatekeeperCookies(cookieValue + 1);
        }
      }, waitTime);
      pointerTimerId = setTimeout(function () {
        setPointerEvents('all');
      }, waitTime + waitPointerTime);
    }
    return function () {
      clearTimeout(waitTimerId);
      clearTimeout(pointerTimerId);
    };
  }, []);
  return React.createElement(React.Fragment, null, open && React.createElement(DialogStyled, {
    "data-testid": "gatekeeper",
    fullScreen: true,
    position: "center",
    onClickOutside: handleCloseGatekeeper
  }, React.createElement(ModalContent, null, React.createElement(Header, null, React.createElement(Logo, {
    alt: "Logo Magalu"
  }), React.createElement(Strip, {
    position: "relative",
    size: 1.6
  })), React.createElement(Title, null, title), React.createElement(Subtitle, null, subtitle), React.createElement(GatekeeperButton, {
    pointerEvents: pointerEvents,
    "data-testid": "gatekeeper-link",
    onClick: handleSendToApp,
    full: true
  }, mainButton), React.createElement(DivisorContainer, null, React.createElement(Divisor, null), divisor, React.createElement(Divisor, null)), React.createElement(CloseGateKeeper, {
    "data-testid": "close-gatekeeper",
    onClick: handleCloseGatekeeper
  }, closeGatekeeper))));
};
export default Gatekeeper;
process.env.NODE_ENV !== "production" ? Gatekeeper.propTypes = {
  config: shape({}).isRequired,
  "static": shape({}).isRequired,
  structure: shape({}).isRequired
} : void 0;