import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;
import styled from 'styled-components';
import { getTextColor, themeGet, color } from '@magalu/stereo-ui-styles';
import LinkElement from '@magalu/stereo-ui/atoms/Link';
export var AuxiliaryInfo = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: ", "px 0;\n"])), themeGet('space.2'));
export var CertificatesPartnersList = styled.ul(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n\n  a {\n    display: block;\n  }\n"])));
export var Container = styled.div.attrs(function () {
  return {
    bg: 'background.base'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  margin-top: 24px;\n  padding: 24px 20px;\n  width: 100%;\n\n  ", "\n"])), color);
export var Content = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  max-width: 1366px;\n"])));
export var DevelopedByText = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-size: ", "px;\n  text-align: right;\n"])), themeGet('fontSizes.0'));
export var Image = styled.img(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  max-height: ", ";\n  margin-right: ", "px;\n"])), function (props) {
  return props.maxHeight;
}, themeGet('space.2'));
export var InfoText = styled.p.attrs(function () {
  return {};
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", "px;\n  margin-bottom: ", "px;\n  line-height: ", "px;\n"])), function (props) {
  return getTextColor('base')(props);
}, themeGet('fontSizes.0'), themeGet('space.2'), themeGet('lineHeights.tall'));
export var LinkItem = styled(LinkElement).attrs(function () {
  return {
    color: 'text.base',
    fontSize: 0,
    underlineOnHover: true
  };
})(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  margin: 0 ", "px;\n"])), themeGet('space.2'));
export var LogoParceiro = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n\n  > img {\n    width: 208px;\n  }\n"])));
export var NavigationLinks = styled.ul(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  padding: ", "px 0;\n  display: flex;\n  justify-content: center;\n"])), themeGet('space.2'));
export var PaymentMethodsList = styled.ul(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n\n  img {\n    width: 52px;\n    height: 40px;\n  }\n"])));