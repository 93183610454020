import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import { FinancialServicesDataFallback } from '../FinancialServices/FinancialServicesDataFallback';
import { Container, Section, Paragraph } from './FooterService.styles';
var FooterService = function FooterService(_ref) {
  var data = _ref.data;
  var _ref2 = (data == null ? void 0 : data.serviceContent) || FinancialServicesDataFallback,
    footerContent = _ref2.footerContent;
  return React.createElement("footer", {
    "data-testid": "footer-service-container"
  }, React.createElement(Container, null, footerContent == null ? void 0 : footerContent.map(function (paragraph) {
    return React.createElement(Section, {
      key: paragraph.title,
      "data-testid": "footer-service-section"
    }, React.createElement(Paragraph, null, paragraph.title), React.createElement(Paragraph, null, paragraph.content));
  })));
};
process.env.NODE_ENV !== "production" ? FooterService.propTypes = {
  data: shape({
    serviceContent: shape({
      footerContent: arrayOf(shape({
        content: string,
        title: string
      }))
    })
  })
} : void 0;
FooterService.defaultProps = {
  data: {
    serviceContent: {}
  }
};
export default FooterService;