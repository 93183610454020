import React from 'react';
import { shape } from 'prop-types';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { AuxiliaryInfo, CertificatesPartnersList, Content, Container, DevelopedByText, Image, InfoText, LinkItem, LogoParceiro, NavigationLinks, PaymentMethodsList } from './FooterDesktopPMD.styles';
var FooterDesktopPMD = function FooterDesktopPMD(_ref) {
  var staticProps = _ref["static"];
  var footerData = staticProps.footerData;
  var auxiliaryInfo = footerData.auxiliaryInfo,
    logo = footerData.logo,
    navigationLinks = footerData.navigationLinks,
    paymentMethods = footerData.paymentMethods,
    certificatePartners = footerData.certificatePartners,
    bottomInfo = footerData.bottomInfo;
  return React.createElement(Container, null, React.createElement(Content, null, React.createElement(LogoParceiro, null, React.createElement(Image, {
    "data-testid": "logo-parceiro",
    src: logo.src,
    alt: logo.alt
  })), !!navigationLinks.links && React.createElement(NavigationLinks, null, navigationLinks.links.map(function (link) {
    return React.createElement("li", {
      key: link.label
    }, React.createElement(LinkItem, {
      href: link.url,
      title: link.label,
      target: "_blank",
      rel: "noreferrer"
    }, link.label));
  })), !!auxiliaryInfo.texts && React.createElement(AuxiliaryInfo, null, auxiliaryInfo.texts.map(function (text) {
    return React.createElement(InfoText, {
      key: text
    }, text);
  })), !!paymentMethods.list && React.createElement(AuxiliaryInfo, null, React.createElement(InfoText, null, paymentMethods.label), React.createElement(PaymentMethodsList, null, paymentMethods.list.map(function (item) {
    return React.createElement("li", {
      key: item.title
    }, React.createElement(Image, {
      src: item.img,
      alt: item.title
    }));
  }))), !!certificatePartners.certificates && React.createElement(AuxiliaryInfo, null, React.createElement(InfoText, null, certificatePartners.title.label), React.createElement(CertificatesPartnersList, null, certificatePartners.certificates.map(function (item) {
    return React.createElement("li", {
      key: item.content.alt
    }, React.createElement("a", {
      href: item.content.url,
      target: "_blank",
      rel: "noreferrer",
      title: item.content.alt
    }, React.createElement(Image, {
      src: item.content.img,
      alt: item.content.alt,
      maxHeight: item.content.maxHeight
    })));
  })), React.createElement(DevelopedByText, null, React.createElement("span", null, "desenvolvido por "), React.createElement("strong", null, "magazineluiza"), React.createElement("span", null, ".com"))), !!bottomInfo.texts && React.createElement(AuxiliaryInfo, null, bottomInfo.texts.map(function (text) {
    return React.createElement(InfoText, {
      key: text
    }, text);
  }))));
};
process.env.NODE_ENV !== "production" ? FooterDesktopPMD.propTypes = {
  "static": shape({})
} : void 0;
FooterDesktopPMD.defaultProps = {
  "static": {}
};
FooterDesktopPMD.ssr = true;
FooterDesktopPMD.displayName = 'FooterDesktopPMD';
export default withLayoutProps(FooterDesktopPMD);