import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["miniAppName", "eventButton", "sku", "buttonLabel", "href", "label"];
import React from 'react';
import Button from '@magalu/stereo-ui/atoms/Button';
import { shape, string } from 'prop-types';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { routePush } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
var GenericButton = function GenericButton(_ref) {
  var staticProps = _ref["static"];
  var _ref2 = staticProps || {},
    miniAppName = _ref2.miniAppName,
    eventButton = _ref2.eventButton,
    sku = _ref2.sku,
    buttonLabel = _ref2.buttonLabel,
    href = _ref2.href,
    label = _ref2.label,
    props = _objectWithoutProperties(_ref2, _excluded);
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var handleClickLinkInformation = function handleClickLinkInformation(e) {
    e.preventDefault();
    routePush({
      path: href,
      spa: true
    });
    publish(eventButton, {
      buttonLabel: buttonLabel,
      miniAppName: miniAppName,
      sku: sku
    });
  };
  return React.createElement(Button, _extends({
    "data-testid": "button-container",
    href: href,
    onClick: handleClickLinkInformation
  }, props), label);
};
GenericButton.defaultProps = {
  "static": {
    href: '',
    label: ''
  }
};
process.env.NODE_ENV !== "production" ? GenericButton.propTypes = {
  "static": shape({
    href: string,
    label: string
  })
} : void 0;
GenericButton.ssr = true;
export default withLayoutProps(GenericButton);