import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { shape } from 'prop-types';
import StereoFooterDesktop from '@magalu/stereo-ui/templates/FooterDesktop';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { routePush } from '@magalu/mixer-utils';
import { Container } from './FooterDesktop.styles';
import adaptStaticToStereoFooterProps from './adaptStaticToStereoFooterProps';
var FooterDesktop = function FooterDesktop(_ref) {
  var staticProps = _ref["static"];
  var footerProps = adaptStaticToStereoFooterProps({
    staticProps: staticProps
  });
  var handleMenuItemClick = function handleMenuItemClick(e, link) {
    e.preventDefault();
    routePush({
      path: link == null ? void 0 : link.url,
      spa: true
    });
  };
  return React.createElement(Container, null, React.createElement(StereoFooterDesktop, _extends({}, footerProps, {
    onMenuItemClick: handleMenuItemClick
  })));
};
process.env.NODE_ENV !== "production" ? FooterDesktop.propTypes = {
  "static": shape({})
} : void 0;
FooterDesktop.defaultProps = {
  "static": {}
};
FooterDesktop.ssr = true;
FooterDesktop.displayName = 'FooterDesktop';
export default withLayoutProps(FooterDesktop);