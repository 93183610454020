import _extends from "@babel/runtime/helpers/esm/extends";
export default (function (_ref) {
  var staticProps = _ref.staticProps;
  var _staticProps$footerDa = staticProps.footerData;
  _staticProps$footerDa = _staticProps$footerDa === void 0 ? {} : _staticProps$footerDa;
  var _staticProps$footerDa2 = _staticProps$footerDa.certificatePartners,
    certificatePartners = _staticProps$footerDa2 === void 0 ? [] : _staticProps$footerDa2,
    _staticProps$footerDa3 = _staticProps$footerDa.linksColumns,
    linksColumns = _staticProps$footerDa3 === void 0 ? [] : _staticProps$footerDa3,
    _staticProps$footerDa4 = _staticProps$footerDa.contactSection,
    contactSection = _staticProps$footerDa4 === void 0 ? {} : _staticProps$footerDa4,
    _staticProps$footerDa5 = _staticProps$footerDa.bottomInfo,
    bottomInfo = _staticProps$footerDa5 === void 0 ? {} : _staticProps$footerDa5,
    paymentMethods = _staticProps$footerDa.paymentMethods,
    subscriptionForm = _staticProps$footerDa.subscriptionForm;
  return {
    data: _extends({
      bottomInfo: bottomInfo,
      certificatePartners: certificatePartners,
      contactSection: contactSection,
      linksColumns: linksColumns
    }, paymentMethods && {
      paymentMethods: paymentMethods
    }, subscriptionForm && {
      subscriptionForm: subscriptionForm
    })
  };
});