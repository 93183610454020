import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
import styled from 'styled-components';
import Button from '@magalu/stereo-ui/atoms/Button';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import { LogoMagalu } from '@magalu/stereo-ui-icons';
import Dialog from '@magalu/stereo-ui/atoms/Dialog';
import { themeGet } from '@magalu/stereo-ui-styles';
export var CloseGateKeeper = styled(Text).attrs(function () {
  return {
    color: 'primary',
    fontSize: 0,
    fontWeight: 'medium',
    marginBottom: 2,
    marginTop: 3
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var DialogStyled = styled(Dialog)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding-bottom: ", "px;\n  padding-left: ", "px;\n  padding-right: ", "px;\n  padding-top: ", "px;\n  width: 260px;\n  position: absolute;\n  top: 115px;\n"])), themeGet('space.3'), themeGet('space.3'), themeGet('space.3'), themeGet('space.3'));
export var Divisor = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  height: 0.5px;\n  width: 93px;\n"])), themeGet('palette.text.scratched'));
export var DivisorContainer = styled(Flex).attrs(function () {
  return {
    color: 'text.scratched',
    marginBottom: 3,
    marginTop: 4
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  align-items: center;\n  width: 100%;\n  justify-content: space-between;\n  height: 14px;\n"])));
export var GatekeeperButton = styled(Button).attrs(function () {
  return {
    color: 'primary',
    marginBottom: 1,
    marginTop: 4
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  pointer-events: ", ";\n  height: 38px;\n"])), function (props) {
  return props.pointerEvents;
});
export var Header = styled(Flex).attrs(function () {
  return {
    marginBottom: 4,
    marginTop: 2
  };
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  flex-direction: column;\n  width: 114px;\n"])));
export var Logo = styled(LogoMagalu).attrs(function () {
  return {
    color: 'primary.base',
    height: 25,
    viewBox: '0 0 60 14',
    width: 114
  };
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  vertical-align: middle;\n"])));
export var ModalContent = styled(Flex)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n"])));
export var Subtitle = styled(Text).attrs(function () {
  return {
    color: 'scratched',
    fontSize: 1,
    fontWeight: 'regular',
    lineHeight: 'display',
    marginBottom: 1
  };
})(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral([""])));
export var Title = styled(Text).attrs(function () {
  return {
    color: 'dark',
    fontSize: 2,
    lineHeight: 'display'
  };
})(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n"])), themeGet('fontWeights.medium'));