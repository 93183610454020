import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled from 'styled-components';
import { typography } from 'styled-system';
import { color, getTextColor } from '@magalu/stereo-ui-styles';
import Box from '@magalu/stereo-ui/atoms/Box';
import Text from '@magalu/stereo-ui/atoms/Text';
export var Container = styled(Box).attrs(function () {
  return {
    bg: 'background.whisper',
    px: 2,
    py: 2
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Paragraph = styled(Text).attrs(function (props) {
  return _extends({
    fontSize: 0,
    py: 1
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  color: ", ";\n  ", "\n  ", "\n"])), getTextColor('footer'), typography, color);
export var Section = styled(Box).attrs(function () {
  return {
    py: 1
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));